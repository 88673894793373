@font-face {
  font-family: 'Inter Light';
  src: local('Inter-Light'), url('../assets/font/Inter-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Inter Bold';
  src: local('Poppins-Bold'), url('../assets/font/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Inter SemiBold';
  src: local('Inter-SemiBold'), url('../assets/font/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter Medium';
  src: local('Poppins-Medium'), url('../assets/font/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter Regular';
  src: local('Inter-Regular'), url('../assets/font/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}

[data-amplify-authenticator] {
  position: absolute;
  /* or fixed */
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

body {
  margin: 0px;
}

body,
div,
p,
label,
span,
button,
input,
textarea,
a,
li,
select {
  font-family: Inter Regular !important;
}

.logout-box {
  .MuiPopover-paper {
    min-width: 272px;
    // min-height: 270px;
    min-height: 140px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06) !important;
    border-radius: 8px !important;
  }

  .picture-big {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .pic-name {
    font-size: 16px;
    padding-left: 10px;
    font-family: Inter SemiBold !important;
    color: #1e1e1e !important;
    cursor: pointer;
  }

  .top-border {
    padding: 20px 20px 20px 20px;
    border-bottom: 1px solid #ced4da;
  }

  .id-info {
    font-size: 12px;
    color: rgba(30, 30, 30, 0.7);
    line-height: 14px;
    margin-top: 2px;
    padding-left: 10px;
  }
}

.header {
  background-color: #ffffff;
  height: 64px;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  padding: 0 15px;
  justify-content: space-between;
  z-index: 100;

  .picture {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .pic-name {
    font-size: 15px;
    padding-left: 9px;
    font-family: Inter Medium !important;
    color: #1e1e1e !important;
    cursor: pointer;
  }

  .logo {
    font-family: Inter Bold !important;
    font-size: 26.4727px;
    line-height: 32px;
    color: #2361ff;
    padding-left: 110px;
  }

  .normal-txt {
    font-size: 16px !important;
    line-height: 19px !important;
    color: rgba(48, 48, 48, 0.5) !important;
    font-family: Inter SemiBold !important;
    height: 64px;
    min-width: 100px;
    cursor: pointer;
  }

  .active-txt {
    font-size: 16px !important;
    line-height: 19px !important;
    height: 64px;
    cursor: pointer;
    min-width: 100px;
    border-bottom: 4px solid #2361ff;
    color: rgba(48, 48, 48, 1) !important;
    font-family: Inter SemiBold !important;
  }
  .normal-txt {
    font-size: 16px !important;
    line-height: 19px !important;
    height: 64px;
    cursor: pointer;
    min-width: 100px;
    color: rgba(48, 48, 48, 0.5) !important;
    font-family: Inter SemiBold !important;
  }
}

.innerbody {
  min-height: calc(100vh - 144px);
  background-color: #f9f9fb;
  padding: 40px;
}

.inner-table-row {
  min-height: 84px;
  background-color: #ffffff;
  border: 1px solid #cfd3f0;
  border-radius: 12px;
  padding: 0px 40px;

  .inner-title {
    font-size: 12px !important;
    font-family: Inter SemiBold !important;
    margin-bottom: 12px;
    color: #303030 !important;
  }

  .download-btn {
    color: #28b895 !important;
    border-radius: 18px;
    border: 1px solid #28b895;
    font-size: 12px !important;
    font-family: Inter SemiBold !important;
    text-transform: capitalize !important;
    padding: 9px 15px 9px 15px;
  }

  .clonedoc-btn {
    color: #0a3fbc !important;
    border-radius: 18px;
    border: 1px solid #0a3fbc;
    font-size: 12px !important;
    font-family: Inter SemiBold !important;
    text-transform: capitalize !important;
    padding: 9px 15px 9px 15px;
    cursor: pointer;
    // opacity: 0.3;
  }

  .cloneim-btn {
    color: #ff8660 !important;
    border-radius: 18px;
    border: 1px solid #ff8660;
    font-size: 12px !important;
    font-family: Inter SemiBold !important;
    text-transform: capitalize !important;
    padding: 9px 15px 9px 15px;
    cursor: not-allowed;
    opacity: 0.3;
  }
  .mlresult-btn {
    color: #6dff60 !important;
    border-radius: 18px;
    border: 1px solid #6dff60;
    font-size: 12px !important;
    font-family: Inter SemiBold !important;
    text-transform: capitalize !important;
    padding: 9px 15px 9px 15px;
    cursor: pointer;
    // opacity: 0.3;
  }
}

.index-table {
  table {
    background-color: #ffffff;

    .mail-txt {
      font-family: Inter SemiBold !important;
      font-size: 14px !important;
      line-height: 17px !important;
      color: #303030 !important;
    }

    thead {
      th,
      p {
        background: #eff1ff;
        font-family: Inter SemiBold !important;
        font-size: 14px !important;
      }
      .upArrow {
        position: absolute;
        bottom: -3px;
      }
      .downArrow {
        position: absolute;
        top: -2px;
      }
    }

    td {
      font-size: 14px !important;
      padding: 8px 12px;

      span {
        padding: 0px 6px 0px 0px !important;
      }
    }

    .MuiTableRow-root {
      padding: 0px !important;
    }

    .back-color {
      background: #eff1ff;

      .MuiFormControl-root {
        flex: 1;
      }

      .MuiInputBase-input {
        padding: 3.5px 14px;
      }

      .MuiInputLabel-formControl {
        font-size: 12px;
        transform: translate(14px, 7px) scale(1);

        &.Mui-focused {
          transform: translate(14px, -9px) scale(0.75);
        }
      }
      // td {
      //   position: sticky;
      //   top: 57.33px;
      //   z-index: 2;
      //   background: #eff1ff;
      // }
    }
  }
}
.index-tab {
  .MuiTabPanel-root {
    padding: 0px !important;
  }

  .MuiTabs-indicator {
    height: 4px !important;
    background-color: #2361ff !important;
  }

  .MuiTab-wrapper {
    font-family: Inter SemiBold !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
  }

  .MuiTab-root {
    min-height: 53px !important;
  }

  @media (min-width: 600px) {
    .MuiTab-root {
      min-width: 126px !important;
    }
  }
}

.d-flex-a {
  display: flex;
  align-items: center;
}

.d-flex-ja {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex-j {
  display: flex;
  justify-content: center;
}

.d-flex-sb {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.flex-1 {
  flex: 1;
}

.medium-txt {
  font-family: Inter Medium !important;
  font-size: 14px !important;
  line-height: 16px;
  color: #1e1e1e !important;
}

.medium-title {
  font-family: Inter SemiBold !important;
  font-size: 14px !important;
  line-height: 16px;
  color: #303030 !important;
}

.red-txt {
  font-family: Inter Medium !important;
  font-size: 14px !important;
  line-height: 16px;
  color: #ff0000 !important;
}

.blue-text {
  font-family: Inter Medium !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #2361ff !important;
  text-decoration: underline solid #2361ff;
}

.chat-box {
  margin-bottom: 10px !important;
  border-bottom: 1px solid #9292;
  padding-bottom: 12px;
  display: inline-block;
  position: relative;
  height: auto;
  min-width: 150px;
  .blue-textchat {
    font-family: Inter SemiBold !important;
    font-size: 16px !important;
    line-height: 16px !important;
    color: #333 !important;
    margin-bottom: 6px !important;
  }
  .f-12 {
    font-size: 12px !important;
    color: #929292;
  }
  .f-14 {
    font-family: Inter Regular !important;
    font-size: 14px !important;
    line-height: 21px;
  }
  .text-break {
    word-break: break-all;
  }
}
.iclarify {
  max-height: 30vh; 
  overflow: auto;
}
.parnet-chat {
  border: 1px solid #7a7a7a;
  padding: 24px;
  border-radius: 5px;
}
.result-txt {
  font-family: Inter Medium !important;
  font-size: 14px !important;
  line-height: 28px !important;
  color: #959595 !important;
  padding-right: 20px;
  border-right: 1px solid #d9d9d9;
}

.version-text {
  font-size: 12px !important;
  line-height: 15px;
  color: rgba(30, 30, 30, 0.7) !important;
}

.back-text {
  font-family: Inter SemiBold !important;
  font-size: 20px !important;
  line-height: 24px;
  color: #303030 !important;
}

.pdf-box {
  height: calc(100vh - 180px);
  overflow-y: hidden;
  background: #ffffff;
  border-bottom: 1px solid #ececec;
  padding-right: 0px !important;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-top: 10px;
}

.pdf-edit-box {
  .edit-head {
    min-height: 62px;
    background: #e5ebfa;
    border-radius: 12px 12px 0px 0px;
    padding: 0px 32px;

    .MuiFormControl-root {
      background-color: #ffffff;
    }

    .MuiSelect-select {
      position: relative;
      z-index: 10;
      padding: 6.5px 14px;
    }

    .d-arrow {
      position: absolute;
      right: 2px;
    }
  }

  .edit-body {
    height: calc(100vh - 60vh);
    overflow-y: auto;
    border-bottom: 1px solid #cfcfcf;
    background-color: #ffffff;
    padding: 24px;

    .reject-btn {
      width: 102px;
      height: 36px;
      font-family: Inter SemiBold !important;
      font-size: 12px !important;
      color: #ffffff !important;
      background: #ff012f;
      border-radius: 5px;
    }

    .save-btn {
      min-width: 88px !important;
      height: 36px !important;
      font-family: Inter SemiBold !important;
      text-transform: capitalize !important;
      font-size: 12px !important;
      color: #ffffff !important;
      background: #2361ff !important;
      border-radius: 5px !important;
    }

    .cancel-btn {
      width: 88px;
      height: 36px;
      font-family: Inter SemiBold !important;
      font-size: 12px !important;
      color: #2361ff !important;
      border: 1px solid #2361ff;
      background-color: #ffffff;
      border-radius: 5px;
    }

    .skip-btn {
      width: 88px;
      height: 36px;
      font-family: Inter SemiBold !important;
      font-size: 12px !important;
      color: #4b546a !important;
      border: 1px solid #4b546a;
    }

    .inner-scroll {
      height: calc(100vh - 75vh);
      overflow-y: auto;
      margin-bottom: 34px !important;

      &::-webkit-scrollbar {
        width: 0.5em;
        height: 0.5em;
        border-radius: 4px;
        cursor: pointer;
      }

      &::-webkit-scrollbar-track-piece {
        background: #e8e8e8;
        border-radius: 100px;
        cursor: pointer;
      }

      &::-webkit-scrollbar-thumb {
        background: #7a7a7a;
        border-radius: 100px;
        cursor: pointer;
      }
    }

    .MuiInputBase-input,
    .MuiSelect-select {
      padding: 8.5px 14px;
    }

    .MuiInputLabel-formControl {
      font-size: 14px;
      transform: translate(14px, 11px) scale(1);

      &.Mui-focused {
        transform: translate(14px, -9px) scale(0.9);
      }
    }

    .count-box {
      width: 40px;
      height: 40px;
      background: #f1f2f6;
      border-radius: 4px;
    }

    .row-space {
      column-gap: 8px;
      margin-bottom: 18px !important;
      padding-right: 11px;
    }

    .raw-box {
      flex: 1;
      background: #f1f2f6;
      border-radius: 4px;
      padding: 11px 12px;
      font-size: 14px !important;
    }

    .cutter-box {
      cursor: pointer;
      width: 40px;
      height: 40px;
      background: #ffebeb;
      border-radius: 4px;
      position: relative;

      .c2 {
        position: absolute;
        top: 15px;
        right: 10px;
      }
    }
  }

  .edit-footer {
    min-height: 185px;
    background-color: #ffffff;
    padding: 0 24px;

    .cancel-btn {
      width: 88px;
      height: 36px;
      font-family: Inter SemiBold !important;
      font-size: 12px !important;
      color: #2361ff !important;
      border: 1px solid #2361ff;
      background-color: #ffffff;
      border-radius: 5px;
    }
  }

  .border-left {
    border-left: 1px solid #d9d9d9;
    padding-left: 20px;
  }
}
.move-btn {
  padding-left: 24px !important;
  padding-right: 24px !important;
      min-width: 88px !important;
      height: 36px !important;
      font-family: Inter Medium !important;
      text-transform: capitalize !important;
      font-size: 12px !important;
      color: #ffffff !important;
      background: #2361ff !important;
      border-radius: 5px !important;
    }
.total-box {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  margin-bottom: 26px !important;

  .total-pare-box {
    padding: 32px 40px 20px 40px;
    border-bottom: 1px solid #f0f0f0;
  }

  .total-in-box {
    background: #ffffff;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    padding: 0 25px;
    height: 80px;
    display: flex;
    align-items: center;

    .p-relative {
      position: relative;
    }

    .p2 {
      position: absolute;
      bottom: -6px;
      left: 0px;
    }

    .t-title {
      font-size: 20px !important;
      line-height: 24px !important;
      font-family: Inter SemiBold !important;
      color: #303030 !important;
    }

    .t-title-16 {
      font-size: 16px !important;
      line-height: 19px !important;
      font-family: Inter SemiBold !important;
      color: #303030 !important;
    }

    .t-info {
      font-size: 12px !important;
      line-height: 15px !important;
      color: #989898 !important;
    }
  }
}

.t-info-16 {
  font-size: 16px !important;
  line-height: 19px !important;
  font-family: Inter SemiBold !important;
  color: #303030 !important;
  padding: 20px 40px;
}

.custom-date {
  .MuiInputBase-input {
    font-size: 14px !important;
    padding: 9.5px 14px;
  }
  input {
    font-size: 14px !important;
  }
  p {
    display: none;
  }
  .MuiInputLabel-formControl {
    font-size: 14px;
    transform: translate(14px, 10px) scale(1);

    &.MuiFormLabel-filled {
      transform: translate(14px, -9px) scale(0.9);
    }
  }
}

.analy-select {
  .MuiSelect-select {
    padding: 8.5px 14px;
  }

  .MuiInputLabel-shrink {
    background-color: #ffffff;
  }
// .MuiFormLabel-root{
//   transform: translate(14px, 10px) scale(1)
// }
  .MuiInputLabel-formControl {
    font-size: 14px;
    margin-top: -5px;
    // transform: translate(14px, 11px) scale(1);

    &.Mui-focused {
      transform: translate(14px, -9px) scale(0.9);
    }
  } 
}
.custom-form {
  input,
  .MuiSelect-select {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .MuiSelect-select {
    padding-top: 8.5px !important;
    padding-bottom: 8.5px !important;
  }
  .custom-input {
    width: 100%;
    .custom-label {
      font-size: 14px !important;
      font-weight: 500 !important;
      margin-bottom: 5px !important;
      font-family: Inter Medium !important;
      span {
        color: red !important;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      legend {
        font-size: 0.65em !important;
      }
    }
    .MuiOutlinedInput-input {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .MuiOutlinedInput-root {
      border-radius: 6px !important;
    }
    .MuiFormLabel-root {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: #3f413f !important;
    }
    .MuiFormControlLabel-label {
      font-weight: 500 !important;
      color: #111 !important;
      font-size: 14px !important;
    }
    .MuiRadio-root {
      padding: 0px 9px !important;
      svg {
        width: 16px !important;
      }
    }
    .MuiInputLabel-root {
      transform: translate(14px, 15px) scale(1);
      color: #ababab !important;
      font-weight: 500 !important;
      font-size: 12px !important;
    }
    .MuiInputLabel-shrink {
      transform: translate(14px, -9px) scale(1) !important;
      background-color: #ffffff;
      color: #333 !important;
      font-weight: 500 !important;
    }
  }
}
.fil-box {
  padding: 24px;
  background-color: #ffffff;
  border-radius: 5px;
  min-height: 80px;
  margin-top: 24px !important;
}
.title20 {
  font-family: Inter SemiBold !important;
  font-size: 20px !important;
  line-height: 22px !important;
  color: #303030 !important;
  margin-bottom: 8px !important;
}
.line-top {
  min-height: 87px;
  background: #eff1ff;
  border-bottom: 1px solid #cfd3f0;
  border-radius: 12px 12px 0px 0px;
  padding: 0 40px;

  .title-18 {
    font-family: Inter SemiBold !important;
    font-size: 18px !important;
    line-height: 22px !important;
    color: #303030 !important;
    margin-bottom: 8px;
  }

  .n-info {
    font-size: 14px !important;
    line-height: 17px !important;
    color: #282828 !important;
  }
}

.line-body {
  min-height: 232px;
  background: #ffffff;
  border-radius: 0px 0px 12px 12px;
  padding: 20px;
}
.MuiInputBase-root {
  svg {
    margin-right: 10px;
  }
}
.w-25 {
  width: 25%;
}
.w-8 {
  width: 8.5%;
}
.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.pl-11 {
  padding-left: 11px;
}

.mr-12 {
  margin-right: 12px !important;
}

.ml-9 {
  margin-left: 9px !important;
}

.p-20 {
  padding: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pl-24 {
  padding-left: 24px;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mr-7 {
  margin-right: 7px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.w-100 {
  width: 100%;
}

.mt-15 {
  margin-top: 15px !important;
}
.mt-24 {
  margin-top: 24px !important;
}
.mr-15 {
  margin-right: 15px !important;
}

.mb-26 {
  margin-bottom: 26px !important;
}

.l-container {
  background-color: #479cd6f5;
  min-height: 100vh;

  .center-box {
    width: 440px;
  }

  .top {
    height: 367px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: #ffffff;
    padding: 15px 40px;
    border-radius: 16px 16px 0px 0px;

    .top-title {
      font-family: Inter Bold !important;
      font-size: 32px !important;
      line-height: 39px !important;
      color: #2361ff !important;
      text-align: center;
      margin-bottom: 33px !important;
    }

    .for-txt {
      font-size: 14px !important;
      line-height: 17px !important;
      text-align: center;
      cursor: pointer;
      color: #2361ff !important;
    }

    .login-btn {
      height: 36px;
      width: 40%;
      background: #2361ff;
      border-radius: 5px;
      font-family: Inter SemiBold !important;
      font-size: 12px !important;
      line-height: 15px;
      color: #ffffff !important;
    }
  }
  .bottom {
    height: 72px;
    background: #eef2ff;
    border-radius: 0px 0px 16px 16px;

    .power {
      color: #b7bac0 !important;
      font-size: 12px !important;
      margin-right: 12px !important;
    }
  }
}

.logout-title {
  font-family: Inter SemiBold !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #1e1e1e !important;
  margin-bottom: 16px !important;
}

.logout-dis {
  font-size: 14px !important;
  line-height: 16px !important;
  color: #1e1e1e !important;
  margin-bottom: 16px !important;
}

.MuiBox-root {
  &:focus-visible {
    outline: none !important;
    border: none !important;
  }
}

.logout-btn {
  width: 103px;
  height: 36px;
  background: #2361ff !important;
  font-family: Inter Medium !important;
  border-radius: 4px;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.l-cancel-btn {
  width: 103px;
  height: 36px;
  font-family: Inter Medium !important;
  font-size: 14px !important;
  line-height: 16px !important;
  // color: #1e1e1e !important;
  color: #2361ff !important;
  border: 1px solid #2361ff !important;
  background-color: #ffffff !important;
  border-radius: 4px;
  margin-right: 12px !important;
  text-transform: capitalize !important;
}

.reason-title {
  text-align: center;
  padding: 24px;
  font-size: 15px !important;
  line-height: 20px !important;
  font-family: Inter SemiBold !important;
  text-transform: capitalize;
  color: #1e1e1e !important;
  border-bottom: 1px solid #cfcfcf;
}

.reason-box {
  max-height: 90vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
    border-radius: 4px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track-piece {
    background: #e8e8e8;
    border-radius: 100px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: #7a7a7a;
    border-radius: 100px;
    cursor: pointer;
  }

  .reason-item {
    color: #1e1e1e !important;
    font-size: 14px !important;
    padding: 12px 40px;
    cursor: pointer;
    transition: 0.4s;

    &:hover {
      background-color: #eff1ff;
    }
  }
}

.pointer {
  cursor: pointer;
}

.mr-1 {
  margin-right: 1px !important;
}

.mr-2 {
  margin-right: 2px !important;
}

.mr-3 {
  margin-right: 3px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.pdfInput {
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-input {
      padding-right: 8px !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      padding-left: 8px !important;
      color: white !important;
    }
  }
}

.count-box1 {
  width: 40px;
  height: 40px;
  background: #2361ff;
  border-radius: 4px;
  color: white;
}

.css-xg9u7b-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 2px !important;
}
.css-ncklhr .MuiOutlinedInput-root {
  padding: 1px !important;
}

.blink {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-radius: 50%;
  background-color: #2361ff;
  animation: blink-animation 2s steps(5, start) infinite;
  -webkit-animation: blink-animation 2s steps(5, start) infinite;
}
.mlblink {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-radius: 50%;
  background-color: #6dff60 !important;
  animation: blink-animation 2s steps(5, start) infinite;
  -webkit-animation: blink-animation 2s steps(5, start) infinite;
}
.scacblink {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-radius: 50%;
  background-color: #800080 !important;
  animation: blink-animation 2s steps(5, start) infinite;
  -webkit-animation: blink-animation 2s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
.syncbox {
  width: 8px;
  height: 8px;
  border: 0.5px solid #ccc;
  border-radius: 50%;
  position: relative;
  margin-right: 5px !important;
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: 4px;
  left: 4px;
  pointer-events: none;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}
.sortIcon {
  margin-left: 5px;
  font-size: 9px;
}

.p-relative {
  position: relative;
}
.disableField {
  opacity: 0.3 !important;
  cursor: not-allowed !important;
}

.rpv-core__inner-page{
  background-color: #c6cdcd !important;
}

.rpv-core__page-layer--single {
  margin: 20px auto !important;
}

.MuiFormHelperText-root.Mui-error{
  color: rgb(255, 0, 0) !important;
  margin: 0px !important;
  margin-top: 2px !important;
}

.MuiOutlinedInput-notchedOutline{
  border-color: rgba(0, 0, 0, 0.23) !important
}